import "./App.css";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";

function App() {
  const socket = useRef();

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  const userId = "9000000000"; // Your user ID

  // Function to get current location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        error: "Geolocation is not supported by this browser.",
      });
    }
  };

  useEffect(() => {
    // Initialize the socket connection
    socket.current = io("https://womenrapido.nuhvin.com");

    // Set up an interval to fetch coordinates and emit to server every 3 seconds
    const intervalId = setInterval(() => {
      // Fetch the latest coordinates
      getLocation();

      // After fetching coordinates, emit them if they are valid
      if (location.latitude && location.longitude) {
        socket.current.emit("send-coordinates", {
          userId,
          coordinates: {
            lat: location.latitude,
            lng: location.longitude,
          },
        });
        console.log("Coordinates sent:", location);
      }
    }, 3000); // 3 seconds interval

    // Clean up the interval and socket connection when component unmounts
    return () => {
      clearInterval(intervalId);
      socket.current.disconnect();
    };
  }, [location.latitude, location.longitude]);

  return (
    <div className="App">
      <h1>Hello World</h1>
      {location.error && <p>Error: {location.error}</p>}
      {location.latitude && <p>Latitude: {location.latitude}</p>}
      {location.longitude && <p>Longitude: {location.longitude}</p>}
    </div>
  );
}

export default App;
